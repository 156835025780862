import React from "react";
import "../Styles/AboutPage.css";

const About = () => {
  return (
    <div className="about-page">
      <section className="about-section" dir="rtl">
        <h2>הסטודיו שלנו</h2>
        <p>
          גלו את סטודיו אילון: מובילים בחדשנות בינה מלאכותית
          <br />
          <p>
            הסטודיו שלנו נוסד על ידי משה איילון, חובב חדשנות וטכנולוגיה עם
            ניסיון שנים בתעשיית ההייטק. אנו מתמחים בפיתוח פתרונות בינה מלאכותית
            שמשלבים חדשנות, יצירתיות ומומחיות טכנולוגית, כדי להביא לך תוצאות
            מדהימות שישדרגו כל אספקט של העסק שלך.
          </p>
        </p>
      </section>

      <section className="about-section" dir="rtl">
        <h2>מה מיוחד בנו?</h2>
        <p>
          ב-סטודיו אילון, אנו מאמינים שכל פרויקט הוא ייחודי. לכן, אנו מקדישים
          זמן להבין את צרכי הלקוח ולהתאים את הפתרון הטכנולוגי המושלם לצרכים אלה.
          אנו שמים דגש על שיתופי פעולה ארוכי טווח עם הלקוחות שלנו כדי להבטיח שהם
          ימשיכו להיות בחזית החדשנות גם לאחר סיום הפרויקט.
        </p>
      </section>
      <section className="about-section" dir="rtl">
        <h2>המומחיות שלנו</h2>
        <p>
          הצוות של סטודיו אילון מורכב ממומחים בתחומי הבינה המלאכותית, פיתוח
          תוכנה ועיצוב חוויות משתמש. המומחים שלנו מחזיקים בניסיון עשיר בביצוע
          מגוון פרויקטים מורכבים, מייעוץ אסטרטגי ועד פיתוח מערכות מורכבות
          שמתמקדות בלקוחות גדולים ובעלי דרישות גבוהות.
        </p>
      </section>
      
    </div>
  );
};

export default About;
