import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { read, utils } from 'xlsx';
import * as pdfjsLib from 'pdfjs-dist/webpack';
import { Document } from 'docx';

import '../Styles/Chatbot.css';

const API_KEY = "sk-proj-ZRCBNEN52W37nxeq7EAvT3BlbkFJxAWEjo7Y6TDZfKU2ECEP";
const questions = [
  {
    id: 1,
    text: "שלום לך, באיזה מהשרותים שלנו אפשר לעניין אותך?",
    choices: ["ייעוץ", "אוטומציה", "הרצאה/הדרכה"],
    response: {
      "ייעוץ": "יש לנו מגוון רחב של שרותי ייעוץ ונשמח מאוד לעזור לך וכמו כן תוכלו לקרוא על זה בעמוד מוצרים שלנו",
      "אוטומציה": "יש לנו מגוון רחב של שרותי אוטומציה מותאמים ללקוח ונשמח מאוד לעזור לך וכמו כן תוכלו לקרוא על זה בעמוד מוצרים שלנו",
      "הרצאה/הדרכה": "אנחנו מספקים שרותי הדרכה בנושאי בינה מלאכותית וגם הרצאות לפי דרישה אפשר לקרוא עוד בעמוד השרותים שלנו",
    }
  },
  {
    id: 2,
    text: "שנמשיך את השיחה ב?",
    choices: ["WhatsApp", "Email"],
    response: {
      WhatsApp: "אנא רשום/י את הנייד שלך",
      Email: "אנא הקלד/י את המייל שלך",
    },
  },
  {
    id: 3,
    text: "Would you like to send a message or continue the conversation?",
    choices: ["Send via WhatsApp", "Send via Email", "Continue Conversation"],
  }
];

const Chatbot = () => {
  const [messages, setMessages] = useState([]);
  const [input, setInput] = useState('');
  const [files, setFiles] = useState([]);
  const [currentQuestion, setCurrentQuestion] = useState(questions[0]);
  const [userSelection, setUserSelection] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [contactInfo, setContactInfo] = useState('');

  useEffect(() => {
    const loadFiles = async () => {
      const fileUrls = [
        '../UPLOAD/services.pdf',
        '../UPLOAD/services.xlsx',
        '../UPLOAD/Planner5D.docx',
      ];

      for (const url of fileUrls) {
        const response = await fetch(url);
        const blob = await response.blob();
        const file = new File([blob], url.split('/').pop());
        await handleFile(file);
      }
    };

    loadFiles();
  }, []);

  const handleFile = async (file) => {
    const reader = new FileReader();
    reader.onload = async (e) => {
      const data = e.target.result;
      if (file.type === 'application/pdf') {
        const pdf = await pdfjsLib.getDocument({ data }).promise;
        let text = '';
        for (let i = 1; i <= pdf.numPages; i++) {
          const page = await pdf.getPage(i);
          const content = await page.getTextContent();
          const strings = content.items.map(item => item.str);
          text += strings.join(' ') + ' ';
        }
        setFiles(prevFiles => [...prevFiles, { name: file.name, content: text }]);
      } else if (file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
        const workbook = read(data, { type: 'binary' });
        const sheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[sheetName];
        const json = utils.sheet_to_json(worksheet);
        const content = JSON.stringify(json);
        setFiles(prevFiles => [...prevFiles, { name: file.name, content }]);
      } else if (file.type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') {
        const arrayBuffer = await file.arrayBuffer();
        const doc = new Document(arrayBuffer);
        const text = doc.getText();
        setFiles(prevFiles => [...prevFiles, { name: file.name, content: text }]);
      }
    };
    reader.readAsArrayBuffer(file);
  };

  const handleUserChoice = (choice) => {
    const newMessage = { sender: 'user', text: choice };
    setMessages(prevMessages => [...prevMessages, newMessage]);
    setUserSelection(choice);

    if (currentQuestion.id === 1) {
      handleFileChoice(choice);
    } else if (currentQuestion.id === 2) {
      const response = currentQuestion.response[choice];
      const botMessage = { sender: 'bot', text: response };
      setMessages(prevMessages => [...prevMessages, botMessage]);

      if (choice === 'WhatsApp' || choice === 'Email') {
        setCurrentQuestion({ id: 4, text: response, choices: [] });
      } else {
        setCurrentQuestion(null); // End the conversation or set to the next question
      }
    } else if (currentQuestion.id === 3) {
      if (choice === 'Send via WhatsApp' || choice === 'Send via Email') {
        setCurrentQuestion({ id: 4, text: `Please enter your ${choice.includes('WhatsApp') ? 'phone number' : 'email'}.`, choices: [] });
      } else if (choice === 'Continue Conversation') {
        setCurrentQuestion(questions[0]); // Restart the conversation
      }
    }
  };

  const handleFileChoice = (choice) => {
    const response = currentQuestion.response[choice];
    const botMessage = { sender: 'bot', text: response };
    setMessages(prevMessages => [...prevMessages, botMessage]);

    setCurrentQuestion(questions[1]); // Move to next question
  };

  const handleContactInfoSubmit = async () => {
    if (!contactInfo) return;
  
    const newMessage = { sender: 'user', text: contactInfo };
    setMessages(prevMessages => [...prevMessages, newMessage]);
  
    try {
      if (userSelection === 'Send via WhatsApp') {
        await axios.post('http://api.meylon.co.il/send-whatsapp', {
          phoneNumber: contactInfo,
          message: 'Thank you for contacting us! We will get back to you shortly.',
        });
  
        const botMessage = { sender: 'bot', text: 'Message sent successfully via WhatsApp!' };
        setMessages(prevMessages => [...prevMessages, botMessage]);
      } else if (userSelection === 'Send via Email') {
        await axios.post('http://api.meylon.co.il/send-email', {
          email: contactInfo,
          message: 'Thank you for contacting us! We will get back to you shortly.',
        });
  
        const botMessage = { sender: 'bot', text: 'Message sent successfully via Email!' };
        setMessages(prevMessages => [...prevMessages, botMessage]);
      }
    } catch (error) {
      const botMessage = { sender: 'bot', text: `Error: ${error.message}` };
      setMessages(prevMessages => [...prevMessages, botMessage]);
      console.error('Error sending message:', error);
    }
  
    setCurrentQuestion(null); // End the conversation
    setContactInfo(''); // Clear the contact info
  };
  

  const checkFilesForAnswer = (message) => {
    for (const file of files) {
      const regex = new RegExp(message, 'i');
      const match = file.content.match(regex);
      if (match) {
        // Extract a relevant paragraph or section around the match
        const start = Math.max(0, match.index - 100);
        const end = Math.min(file.content.length, match.index + 500); // Increase length to avoid truncation
        const snippet = file.content.substring(start, end);
        return snippet;
      }
    }
    return null;
  };

  const sendMessage = async (message) => {
    if (!message) return;

    const newMessage = { sender: 'user', text: message };
    setMessages(prevMessages => [...prevMessages, newMessage]);
    setInput('');

    const fileAnswer = checkFilesForAnswer(message);
    if (fileAnswer) {
      const botMessage = { sender: 'bot', text: fileAnswer };
      setMessages(prevMessages => [...prevMessages, botMessage]);
    } else {
      try {
        const response = await axios.post('https://api.openai.com/v1/chat/completions', {
          model: "gpt-3.5-turbo",
          messages: [
            { role: "user", content: message }
          ],
          max_tokens: 150
        }, {
          headers: {
            'Authorization': `Bearer ${API_KEY}`,
            'Content-Type': 'application/json'
          }
        });

        const botMessage = { sender: 'bot', text: response.data.choices[0].message.content.trim() };
        setMessages(prevMessages => [...prevMessages, botMessage]);
      } catch (error) {
        const errorMessage = { sender: 'bot', text: `Error: ${error.message}` };
        setMessages(prevMessages => [...prevMessages, errorMessage]);
        console.error('Error fetching response:', error);
      }
    }
  };

  const handleInputKeyPress = (e) => {
    if (e.key === 'Enter') {
      sendMessage(input);
    }
  };

  const toggleChatbot = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="chatbot-container">
      <button className="chatbot-toggle" onClick={toggleChatbot}>
        {isOpen ? 'Close Chat' : 'Open Chat'}
      </button>
      {isOpen && (
        <div className="chatbot">
          <div className="chat-window">
            {messages.map((msg, index) => (
              <div key={index} className={`message ${msg.sender}`}>
                {msg.text}
              </div>
            ))}
            {currentQuestion && currentQuestion.id !== 4 && (
              <div className="message bot">
                {currentQuestion.text}
                <div className="choices">
                  {currentQuestion.choices.map((choice, index) => (
                    <button key={index} onClick={() => handleUserChoice(choice)}>
                      {choice}
                    </button>
                  ))}
                </div>
              </div>
            )}
            {currentQuestion && currentQuestion.id === 4 && (
              <div className="message bot">
                <input
                  type="text"
                  value={contactInfo}
                  onChange={(e) => setContactInfo(e.target.value)}
                  placeholder={userSelection === 'Send via WhatsApp' ? 'Enter your phone number...' : 'Enter your email...'}
                />
                <button onClick={handleContactInfoSubmit}>Submit</button>
              </div>
            )}
          </div>
          <div className="input-area">
            <input 
              type="text" 
              value={input} 
              onChange={(e) => setInput(e.target.value)} 
              placeholder="Type your message..." 
              onKeyPress={handleInputKeyPress}
              disabled={!!currentQuestion && currentQuestion.id === 4} // Disable input if a question is active and asking for contact info
            />
            <button onClick={() => sendMessage(input)} disabled={!!currentQuestion && currentQuestion.id === 4}>Send</button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Chatbot;
