import React from "react";
import ContactForm from "../Components/ContactForm";
import "../Styles/ContactPage.css";

const Contact = () => {
  return (
    <div className="contact-page">
      <section className="contact-section">
        <h2>צרו קשר עם הסטודיו</h2>
        <p dir="rtl">
          האם אתם מעוניינים לדעת יותר על השירותים שלנו או זקוקים לייעוץ מקצועי
          בתחום הבינה המלאכותית? <br /> אנו כאן כדי לעזור! צרו איתנו קשר באחת מהדרכים
          הבאות ונשמח לשוחח איתכם.
        </p>
      </section>
      <section className="contact-details">
        <div className="contact-detail">
          <h3>Email</h3>
          <p>meylon@gmail.com</p>
        </div>
        <div className="contact-detail">
          <h3>Phone</h3>
          <p>052-8850905 / 053-5850905</p>
        </div>
      </section>
      <h3 className="contact-sub" dir="rtl">נשמח לשמוע מכם ולעזור בכל שאלה או בקשה.</h3>
      <ContactForm />
    </div>
  );
};

export default Contact;
