import React, { useState } from "react";
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import Home from "./Pages/Home";
import About from "./Pages/About";
import Services from "./Pages/Services";
import Contact from "./Pages/Contact";
import Chatbot from "./Components/Chatbot";
import "./App.css";
import "./Styles/Footer.css";
import Whatsapp from "./assets/whatsapp.png";
import imgEmail from "./assets/mail.png";
function App() {
  const phoneNumber = "972528850905";
  const message = "היי אני מעוניין בשרות שלכם והגעתי מהאתר";

  const email = "meylon@gmail.com";
  const emailSubject = "Contact from your website";
  const emailBody = "Hello, I would like to get in touch.";
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };
  return (
    <Router>
      <div className="App">
        <header className="App-header">
          <h1 className="studio">סטודיו אילון</h1>
          <p className="sub-header">
            השותפים שלכם ליצירת פתרונות בינה מלאכותית - חוויה שמתחילה כאן!
          </p>
          <nav className="nav-container">
            <div className="nav-header">
              <button className="nav-toggle" onClick={toggleMenu}>
                ☰
              </button>
            </div>
            <ul className={`nav-menu ${isOpen ? "nav-open" : ""}`}>
              <li>
                <Link to="/" onClick={toggleMenu}>
                  ראשי
                </Link>
              </li>
              <li>
                <Link to="/about" onClick={toggleMenu}>
                  אודות
                </Link>
              </li>
              <li>
                <Link to="/services" onClick={toggleMenu}>
                  שרותים
                </Link>
              </li>
              <li>
                <Link to="/contact" onClick={toggleMenu}>
                  צור קשר
                </Link>
              </li>
            </ul>
          </nav>
        </header>
        <main>
          <Routes>
            <Route path="/" element={<Home />} exact />
            <Route path="/about" element={<About />} />
            <Route path="/services" element={<Services />} />
            <Route path="/contact" element={<Contact />} />
          </Routes>
        </main>
        <Chatbot />
        <footer>
          <div className="footer-container">
            <div className="contact-icons">
              <a
                href={`mailto:${email}?subject=${encodeURIComponent(
                  emailSubject
                )}&body=${encodeURIComponent(emailBody)}`}
                className="email-icon"
                target="_blank"
              >
                <img src={imgEmail} alt="Email Icon" />
              </a>
              <a
                href={`https://wa.me/${phoneNumber}?text=${encodeURIComponent(
                  message
                )}`}
                target="_blank"
                rel="noopener noreferrer"
                className="whatsapp-icon"
              >
                <img src={Whatsapp} alt="WhatsApp Icon" />
              </a>
            </div>
            <p className="copyright-text">
              © 2024 כל הזכויות שמורות ל-סטודיו אילון
            </p>
          </div>
        </footer>
      </div>
    </Router>
  );
}

export default App;
