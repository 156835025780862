import React, { useState } from "react";
import ContactForm from "../Components/ContactForm";
import "../Styles/HomePage.css";
const Home = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    // כאן יכולה להיות פונקציה ששולחת את הנתונים לשרת או להתייחסות אחרת
    console.log("Form data submitted:", formData);
    alert("Thank you for your message!");
    // ניקוי הטופס
    setFormData({
      name: "",
      email: "",
      message: "",
    });
  };
  return (
    <div className="home-page">
      <section className="home-about">
        <h2>ברוכים הבאים ל-סטודיו אילון – המרכז שלכם לחדשנות בבינה מלאכותית</h2>
        <p>
          אנו מאמינים בכוחה של הבינה המלאכותית לשנות את העולם. עם צוות מומחים
          בחזית הטכנולוגיה, אנו מספקים פתרונות חדשניים המותאמים לצרכי הלקוח.
        </p>
      </section>
      <section className="home-about">
        סטודיו אילון מרכז המצוינות שלכם לפתרונות בינה מלאכותית. <br /> בעידן בו
        הטכנולוגיה משנה כל תחום ומקצוע, אנו כאן כדי להוביל אתכם לחדשנות
        טכנולוגית שתשדרג כל פן בעסק שלכם. <br />
        מייעוץ מקצועי ועד פיתוח פתרונות מותאמים אישית, צוות המומחים שלנו מחויב
        להבטיח שאתם תהנו מהמובילים בתחום.
        <br />
      </section>
      
      <section className="home-about">
        אנו מאמינים שהדרך להצלחה עוברת דרך שילוב של חדשנות, יצירתיות ומצוינות
        טכנולוגית.
        <br /> עם ניסיון עשיר והבנה עמוקה של האתגרים העכשוויים, אנו מספקים
        פתרונות שמתאימים בדיוק לצרכים הייחודיים של כל לקוח.
        <br />
      </section>
      <section className="home-about">
        הצטרפו אלינו למסע מרתק לעולם בו טכנולוגיות מתקדמות מפגישות בין האפשר
        לממשי, <br />וגלו כיצד אנו יכולים לעזור לכם לשפר, לחדש ולהתפתח. בין אם אתם
        זקוקים לייעוץ, לאוטומציה של תהליכים, להרצאות או להדרכות מעמיקות, סטודיו
        אילון הוא שותף הטכנולוגיה שלכם להצלחה.<br /><br />
        צרו קשר עוד היום והתחילו להוביל בחזית החדשנות בענף שלכם. מחכים לכם לשוחח ולגלות יחד את הפוטנציאל האמיתי של העסק שלכם.<br /><br/>
        בואו להיות חלק מהעתיד עם סטודיו אילון – כי כשמדובר בחדשנות, אנחנו כאן להוביל אתכם להצלחה
      </section>
      <h2 className="header-sub">שרותי הסטודיו</h2>
      <section className="home-services centered-section">
        <div className="service-div centered-content auto-image">
          <h2>אוטומציה</h2>
          <p className="ser-auto">
            מתמחים בפיתוח מערכות אוטומציה שמשלבות בינה מלאכותית לשיפור תהליכים
            עסקיים. המערכות שלנו מבטיחות יעילות מרבית, חיסכון בעלויות ושיפור
            ביצועים.
          </p>
        </div>
        <div className="service-div centered-content adv-image">
          <h2>ייעוץ</h2>
          <p className="ser-auto">
            הגעתם למקום הנכון לקבלת ייעוץ מומחה בתחום הבינה המלאכותית. ב-סטודיו
            אילון, אנו מספקים ייעוץ אסטרטגי, טכני ועסקי, על מנת לשלב את
            הטכנולוגיה המתקדמת ביותר בתוך הארגון שלך.
          </p>
        </div>
        <div className="service-div centered-content learn-image">
          <h2>הדרכה והרצאות</h2>
          <p className="ser-auto">
            מספקים הדרכה מקצועית בשימוש בטכנולוגיות AI, עם דגש על למידה מעשית
            ויישום בפועל. הקורסים שלנו מעניקים את הכלים והידע הנדרשים להפוך כל
            אחד למומחה בתחומו.
          </p>
        </div>
      </section>
      <section className="home-contact">
        <ContactForm />
      </section>
    </div>
  );
};

export default Home;
