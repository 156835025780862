import React, { useState } from 'react';
import '../Styles/ContactForm.css';
import axios from 'axios';

const ContactForm = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: '',
    notRobot: false
  });

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === 'checkbox' ? checked : value
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (formData.notRobot) {
      try {
        await axios.post('http://localhost:5000/send-email', {
          subject: 'הודעה מהאתר שלך',
          text: `Name: ${formData.name}\nEmail: ${formData.email}\nMessage: ${formData.message}`
        });
        alert('Message sent successfully');
      } catch (error) {
        console.error('Error sending message:', error);
        alert('Failed to send message');
      }
    } else {
      alert('Please confirm you are not a robot');
    }
  };

  return (
    <div className="contact-form-container">
      <form onSubmit={handleSubmit} className="contact-form">
        <h2 className="form-title">כתוב לנו</h2>
        <label className="form-label" htmlFor="name">שם</label>
        <input
          type="text"
          name="name"
          id="name"
          value={formData.name}
          onChange={handleChange}
          className="form-input"
        />
        <label className="form-label" htmlFor="email">אימייל</label>
        <input
          type="email"
          name="email"
          id="email"
          value={formData.email}
          onChange={handleChange}
          className="form-input"
        />
        <label className="form-label" htmlFor="message">הודעה</label>
        <textarea
          name="message"
          id="message"
          value={formData.message}
          onChange={handleChange}
          className="form-textarea"
          placeholder="כתוב כאן..."
        />
        <div className="form-checkbox-container">
          <input
            type="checkbox"
            name="notRobot"
            id="notRobot"
            checked={formData.notRobot}
            onChange={handleChange}
            className="form-checkbox"
          />
          <label className="form-checkbox-label" htmlFor="notRobot">אני לא רובוט</label>
        </div>
        <button type="submit" className="form-button">שלח הודעה</button>
      </form>
    </div>
  );
};

export default ContactForm;
