import React from "react";
import "../Styles/ServicesPage.css";

const Services = () => {
  return (
    <div className="services-page">
      <section className="our-mission">
        <h2>
          
          המטרה שלנו היא להביא <span>את העסק שלכם</span> לקידמת הטכנולוגיה
        </h2>
      </section>

      <section className="pricing-plan">
        <h2>אנחנו הופכים את העסק שלך לפשוט, מהיר ופחות יקר</h2>
        <div className="plans">
          <div className="plan" dir="rtl">
            <h2>ייעוץ</h2>
            <p>
              אנו מציעים שירותי ייעוץ בכירים בתחום הבינה המלאכותית, שנועדו לעזור
              לארגונים לנצל את פוטנציאל הטכנולוגיה המודרנית בצורה המיטבית.
              המומחים שלנו מספקים פתרונות מותאמים אישית, שמתחשבים בצרכים
              הייחודיים של כל לקוח ובמטרות העסקיות שלו.
              <br />
              <h3>תחומי הייעוץ שלנו כוללים:</h3>
              <b>אסטרטגיה טכנולוגית</b> - פיתוח אסטרטגיות שילוב טכנולוגיות חדשות
              לתוך תהליכי העסק.
              <br />
              <b>אופטימיזציה של תהליכים</b> - שיפור ומיטוב תהליכים קיימים
              באמצעות בינה מלאכותית.
              <br />
              <b>חדשנות ויצירתיות</b> - ייעוץ לפיתוח מוצרים חדשים ושירותים
              מותאמים לדרישות השוק המודרני.
              <br />
              הייעוץ שלנו מאופיין בגישה מעמיקה ואישית, המאפשרת ללקוחותינו להשיג
              תוצאות טובות יותר, תוך כדי שיפור ביצועים וגדילה בתחרותיות.
            </p>
          </div>
          <div className="plan">
            <h2>אוטומציה</h2>
            <p dir="rtl">
            אנו מתמחים באוטומציה של תהליכים עסקיים באמצעות שימוש בטכנולוגיות בינה מלאכותית מתקדמות. פתרונות האוטומציה שלנו מיועדים להפוך כל פעילות עסקית ליעילה יותר, מהירה יותר ומשתלמת יותר.<br />
            <h3>תחומי האוטומציה שלנו כוללים:</h3><br />
            <b>אוטומציה של תהליכים עסקיים</b> - פיתוח מערכות אוטומטיות שמשפרות את ניהול הזמן ומשאבים.<br />
            <b>שילוב מערכות חכמות</b> - שילוב של מערכות בינה מלאכותית בתהליכים היומיומיים של העסק לשיפור תפוקה וקטינה בשגיאות.<br />
            <b>ממשקי משתמש חכמים</b> - פיתוח ועיצוב ממשקים שמקלים על המשתמשים ומאפשרים שימוש נוח ויעיל במערכות האוטומטיות.<br />
            שירותי האוטומציה שלנו מבטיחים שארגונכם יוכל להתמודד עם האתגרים הטכנולוגיים המודרניים ביעילות רבה, תוך כדי שמירה על גמישות לשינויים והתאמות בעתיד.
            </p>
          </div>
          <div className="plan">
            <h2>הרצאה\הדרכה</h2>
            <p dir="rtl">
              אנו מבינים שהידע הוא כוח. לכן, אנו מציעים מגוון רחב של הרצאות
              והדרכות שמטרתן להעמיק ולהרחיב את הבנתכם בתחומים השונים של הבינה
              המלאכותית והטכנולוגיות המתקדמות. דרך שילוב של תאוריה ופרקטיקה,
              הפעילויות הללו מעניקות למשתתפים יכולות מעשיות שיכולות לשנות את
              האופן שבו הם עובדים, חושבים ויוצרים חדשנות.
              <br />
              <h3>שרותי ההרצאות שלנו כוללים:</h3>
              <b>הרצאות מותאמות אישית</b> - התוכן והנושאים נבחרים בהתאמה לצרכים
              ולעניינים של הארגון או הקהל הספציפי.<br />
              <b>מרצים מובילים בתחומם</b> - ההרצאות מועברות על ידי מומחים ומרצים
              בכירים בתחום הבינה המלאכותית, עם ניסיון רחב בהדרכה ובפיתוח
              טכנולוגיות. שימוש בחומרי למידה עדכניים - כל ההרצאות מלוות בחומרי
              למידה מתקדמים ומעודכנים, כולל מצגות, דוגמאות מעשיות ותרגולים.
              <h3>שרותי ההדרכה שלנו מציעים:</h3>
              <b>סדנאות פרקטיות</b> - סדנאות הן מפגשים אינטראקטיביים שבהם
              המשתתפים לומדים ליישם ידע טכני בפועל, תוך כדי שימוש בכלים
              ובטכנולוגיות מתקדמות.
              <br />
              <b>הדרכות מותאמות לכל רמה </b> - מתאים לכל הרמות, ממתחילים ועד
              מתקדמים, עם דגש על התפתחות מקצועית והעצמה אישית.
              <br />
              <b>תמיכה וליווי אחרי הסדנה</b> - כל משתתף מקבל גישה לתמיכה וליווי
              אחרי הסדנה, כדי להבטיח שהכלים והידע שנרכשו ייושמו בהצלחה בסביבת
              העבודה. בחירה בשירותי ההרצאות וההדרכה של סטודיו אילון היא השקעה
              בעתיד המקצועי והאישי שלך או של העובדים בארגונך. אנו מתחייבים לספק
              חוויה מעשירה, מעמיקה ומשפיעה, שתשאיר אתכם עם כלים חדשים להתמודדות
              עם אתגרי העתיד.
            </p>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Services;
